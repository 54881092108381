import TeamCard from "@components/TeamCard/TeamCard"
import useTeams from "@hooks/dataHooks/useTeams"
import React, { useEffect, useState } from "react"
import "./TeamsSlider.scss"
import SliderTeamModule from "@components/SliderModule/SliderTeamModule"
import { shuffle } from "lodash"

const DTeamsSlider = ({ title, description, developer }) => {
  const allTeams = useTeams()
  const [randomTeams, setRandomTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([])
  useEffect(() => {
    const teamsToDisplay = allTeams.filter(team =>
      team?.category?.strapi_json_value?.some(cat => cat === "Brokers")
    );

    const shuffledTeams1 = shuffle(teamsToDisplay).slice(0, 6);
    setRandomTeams(shuffledTeams1);

    let teamsfil = teamsToDisplay.filter(team => {
      // Check if any developer's name or slug in the team matches developer.label
      return team?.developers?.some(dev =>
        dev?.name === developer?.label || dev?.slug === developer?.value
      )
    });
    const shuffledTeams = shuffle(teamsfil).slice(0, 6);
    setFilteredTeams(shuffledTeams)
  }, [developer])
  

  const sliderChildren =  filteredTeams?.length > 0 ? filteredTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} isTeam/>) : randomTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} isTeam/>)

  return (
    sliderChildren?.length > 0 &&
    <SliderTeamModule
    // heading={heading}
      title={title}
      // ctaText={cta_text}
      description={description}
      sliderChildren={sliderChildren}
      wrapperClassname="teams-slider-wrap dd"
      size="xl"
      sectionClassname="section-m"
    />
  )
}

export default DTeamsSlider
